import React, { Fragment } from 'react'

export const Navbar = () => {
    return (
        <Fragment>
            <nav className="navbar navbar-dark bg-dark py-0">
                <div className="container">
                    <a className="navbar-brand" href="/"></a>
                    <a className="btn btn-secondary p-2 px-4" href="/sign-in">Sign in</a>
                </div>
            </nav>
            <div className="container">
                <p className="text-end">
                    Don't have an account? <a href="/sign-up" className="btn btn-link pe-0"><strong>CREATE NOW!</strong></a>
                </p>
            </div>
        </Fragment>
    )
}

export default Navbar

import './App.scss';
import React, {Fragment} from 'react';
import Navbar from './components/layout/Navbar';
import Landing from './components/layout/Landing';
import Footer from './components/layout/Footer';

const App = () => {
  return (
    <Fragment>
      <Navbar />
      <Landing />
      <Footer />
    </Fragment>
  );
}

export default App;
